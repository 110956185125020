import React, { useState } from 'react'
import "./TableWithCrudComponent.scss"
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import _ from 'lodash';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditJsonRaw from './EditJsonRaw';
import DeleteConfirmForm from 'components/DeleteConfirmForm';
import copyToClipboard from 'utility/copyToClipboard';
import ButtonFno from 'components/inputs/ButtonFno';

type TableWithCrudTranslations<E> = {
    tableTitle: string,
    deleteText: (entity: E) => string,
    deleteTitle: (entity: E) => string,
    updateText?: (entity: E) => string,
    noEntityText?: string,
    createTitle?: string,
    createButtonText?: string
}

/**
 * Defines options for a table with CRUD operations.
 */
interface TableWithCrudOptions<E extends {id: string}>{
    entities: E[]; // An array of entities of type E.
    columns: TableColumn<E>[]; // An array of TableColumn for type E.
    fieldSearchable?: (entiy: E) => (string | number | undefined)[]; // A method that takes an entity of type E and returns an array of strings, numbers, or undefined values.
    initialValueCreateForm?: any; // An optional initial value for the create form of type E.
    loadingList: boolean; // Indicates whether the list is loading.
    loadingForm: boolean; // Indicates whether the form is loading.
    addDefaultActionsColumn?: boolean; // Indicates whether to add the default actions column with Edit and delete buttons.
    hideIdColumn?: boolean; // Indicates whether to hide the id column.
    translations:TableWithCrudTranslations<E>; // Translations for TableWithCrudTranslations of type E.
    onUpdate?: (entity: E, closeModal:() => void) => void; // An optional callback function for updating an entity.
    onDelete?: (entity: E, closeModal:() => void) => void; // An optional callback function for deleting an entity.
    onCreate?: (entity: E, closeModal:() => void) => void; // An optional callback function for creating an entity.
    onClickCreateButton?: () => void; // Overrride the default click for the create button.
    customHeader?: JSX.Element | undefined
    //serverSide?: boolean;
    //onSort?: (selectedColumn: TableColumn<E>, sortDirection: SortOrder) => void
    //defaultSortFieldId?: string; // The default sort field
    //paginationTotalRows?: number; // The total number of rows
    //paginationPerPage?: number; // The number of rows per page
}


interface ServerSide<E extends {id: string}> extends TableWithCrudOptions<E>{
    serverSide: true;
    onSort: (selectedColumn: TableColumn<E>, sortDirection: SortOrder) => void;
    onChangeRowsPerPage: (newPerPage: number, page: number) => void;
    onChangePage: (page: number) => void;
    defaultSortFieldId: string; // The default sort field
    defaultSortFieldDirection?: string;
    paginationTotalRows: number;
    paginationPerPage: number;
}

interface LocalSide<E extends {id: string}> extends TableWithCrudOptions<E>{
    serverSide?: false;
    onSort?: () => void;
    onChangeRowsPerPage?: () => void;
    onChangePage?: () => void;
    defaultSortFieldId?: string; // The default sort field
    defaultSortFieldDirection?: string;
    paginationTotalRows?: undefined; // The total number of rows
    paginationPerPage?: undefined; // The number of rows per page
}

/**
 * TableWithCrudComponent is a component that displays a table with CRUD (Create, Read, Update, Delete) operations.
 *
 * @param {TableWithCrudOptions<E>} entities - The list of entities to display in the table
 * @param {Column[]} columns - The columns to display in the table
 * @param {Function} fieldSearchable - The function to determine if a field is searchable
 * @param {boolean} loadingList - Indicates if the table is loading the list of entities
 * @param {boolean} loadingForm - Indicates if the form is loading
 * @param {Function} onUpdate - The function to update an entity
 * @param {Function} onDelete - The function to delete an entity
 * @param {Function} onCreate - The function to create a new entity
 * @param {Translations} translations - The translations for different UI elements
 * @param {Entity} initialValueCreateForm - The initial value for the create form
 * @param {Function} onClickCreateButton - The function to call when the create button is clicked. If set replace default behaviour with onCreate
 * @param {boolean} serverSide - Indicates if the table is server side
 * @return {JSX.Element} The table component with CRUD operations
 */
const TableWithCrudComponent = <E extends {id: string}>({ 
    entities, columns, 
    fieldSearchable, 
    loadingList, loadingForm,
    addDefaultActionsColumn = true,
    hideIdColumn = false,
    onUpdate, onDelete, onCreate, 
    onClickCreateButton,
    translations, initialValueCreateForm,
    defaultSortFieldId, defaultSortFieldDirection = "asc", paginationTotalRows, paginationPerPage,
    serverSide = false, onSort = undefined, onChangeRowsPerPage = undefined,
    onChangePage = undefined,
    customHeader = undefined
}: ServerSide<E> | LocalSide<E> ) => {

    const { t } = useTranslation(nameOf({TableWithCrudComponent}), { useSuspense: false});
    const [searchTerm, setSearchTerm] = useState('');
    const [entityToUpdate, setEntityToUpdate] = useState<E>();
    const [entityToDelete, setEntityToDelete] = useState<E>();
    const [showCreateForm, setShowCreateForm] = useState(false);

    let datableValues = entities;
    if(searchTerm)
    {
        datableValues = entities.filter((entity) => {
            const termsToCheck = fieldSearchable ? fieldSearchable(entity) : [];
            const joinTerm = _.join(_.without(termsToCheck, undefined), ' ').toLocaleLowerCase();
            return joinTerm.includes(searchTerm);
        });
    }


    if(!hideIdColumn)
    {
        columns.unshift({
            id:'id',
            name: t('Id'),
            sortable: true,
            hide:hideIdColumn ? 0 : undefined,
            selector: row => row.id,
            cell: (row, index, column, id) => {
                return <div>
                    <small title={row.id}>... {_.takeRight(row.id, 6)}</small>
                    <Button size='sm' variant='link' onClick={() => copyToClipboard(row.id, t("L'id a bien été copié"))} title={t("copier l'id")}><FontAwesomeIcon icon={["fas", "copy"]} /></Button>
                </div>
            }
        })
    }


    if(addDefaultActionsColumn)
    {
        columns.push({
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div>
                {onUpdate && <Button size='sm' variant='success' onClick={() => setEntityToUpdate(row)}><FontAwesomeIcon icon={["fas", "edit"]} /></Button>}
                {onDelete && <Button size='sm' variant='danger' className='ms-2' onClick={() => setEntityToDelete(row)}><FontAwesomeIcon icon={["fas", "trash"]} /></Button>}
            </div>
            }
        })
    }


    const onUpdateEntity = (entity: E) => {
        if(onUpdate)
        {
            onUpdate(entity, () => setEntityToUpdate(undefined));
        }
    }

    const onCreateEntity = (entity: E) => {
        if(onCreate)
        {
            onCreate(entity, () => setShowCreateForm(false));
        }
    }

    const onDeleteEntity = (entity: E) => {
        if(onDelete)
        {
            onDelete(entity, () => setEntityToDelete(undefined));
        }
    }


    const handleCreateButton = () => {
        if(onClickCreateButton)
        {
            onClickCreateButton();
        }
        else if (onCreate)
        {
            setShowCreateForm(true)
        }
    }

    return (
    <div>
        <div>
            <div className='table-component'>
                {<DataTable 
                    columns={columns} 
                    data={datableValues} 
                    defaultSortAsc={defaultSortFieldDirection == "asc"} 
                    progressPending={loadingList}
                    progressComponent={<Loading text={t("Chargement")}/>}
                    defaultSortFieldId={defaultSortFieldId ?? 'id'} 
                    pagination
                    striped={true}
                    subHeader={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>{translations.noEntityText}</div>}
                    subHeaderComponent={<> 
                        <h5 className='text-uppercase'> {translations.tableTitle} {loadingList && <Loading inline size={20}/>}</h5>
                        <div className='search-panel'>
                            {customHeader}
                            {(!!onCreate || !!onClickCreateButton) && <ButtonFno sm className='me-2' onClick={handleCreateButton}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {translations.createButtonText}</ButtonFno>}
                            {!!fieldSearchable && <input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>}
                        </div>
                        </>}
                    sortServer={serverSide}
                    paginationTotalRows={paginationTotalRows}
                    paginationPerPage={paginationPerPage}
                    onSort={onSort}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    onChangePage={onChangePage}
                    paginationServer={serverSide}/>}
            </div>
        </div>

        {entityToUpdate && onUpdate && <Modal dialogClassName='modal-fno' show={true} onHide={() => setEntityToUpdate(undefined)}>
            <Modal.Header closeButton>
                <Modal.Title>{translations.updateText ? translations.updateText(entityToUpdate) : t("Modifier l'entité")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditJsonRaw onCancel={() => setEntityToUpdate(undefined)} onSubmit={onUpdateEntity} value={entityToUpdate} loading={loadingForm}/>
            </Modal.Body>
        </Modal>}

        {entityToDelete && onDelete && <Modal dialogClassName='modal-fno' show={true} onHide={() => setEntityToDelete(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{translations.deleteTitle(entityToDelete)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeleteConfirmForm onCancel={() => setEntityToDelete(undefined)} 
                                        onSubmit={() => onDeleteEntity(entityToDelete)} 
                                        text={translations.deleteText(entityToDelete)}
                                        loading={loadingForm}/>
                </Modal.Body>
            </Modal>}


            {showCreateForm && <Modal dialogClassName='modal-fno' show={true} onHide={() => setShowCreateForm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{translations.createTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditJsonRaw onCancel={() => setShowCreateForm(false)} 
                                onSubmit={onCreateEntity} 
                                value={initialValueCreateForm} 
                                loading={loadingForm}/>
                </Modal.Body>
            </Modal>}
    </div>)
  };


export default TableWithCrudComponent