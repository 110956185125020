import _ from "lodash";
import { AppThunk } from "store/store";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import { StorageDownloadLinkResult } from "backend/ApiMiddlewareDefinition/data-contracts";




/**
 * Get download url for a batching file
 * @param baseUrl 
 * @param tenant 
 * @param mapId 
 * @param fileId 
 * @param callbacks 
 * @returns 
 */
export const downloadBatchingRawFileThunk = (
    baseUrl: string,
    tenant: string,
    mapId: string,
    fileId: string,
    callbacks: ThrunkApiCallBacks<StorageDownloadLinkResult>
): AppThunk => {
    return async (dispatch, getState) => {
        // Delete audit raw file
        const result = await apiMiddlewareDefinition(baseUrl, tenant).batching.getBatchingRawFileDownloadUrl(mapId, fileId);
        if (!result.ok) {
            // Call onError callback with API error response
            callbacks.onError(result.error as apiErrorModel);
        } else {
            // Call onSuccess callback with true indicating successful deletion
            callbacks.onSuccess(result.data);
        }
    };
};
