/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ApiErrorsResponse,
    BatchingRawFileCreate,
    BatchingRawFileCreateResult,
    BatchingRawFileDetails,
    BatchingRawFileDetailsPagedListResult,
    StorageDownloadLinkResult
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Batching<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Batching
     * @name SearchBatchingRawFiles
     * @request GET:/batching/map/{mapId}/files
     * @secure
     */
    searchBatchingRawFiles = (
        mapId: string,
        query: {
            mapId?: string
            /** @format int32 */
            page: number
            /** @format int32 */
            pageSize: number
            sortColumn?: string
            sortDirection?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<BatchingRawFileDetailsPagedListResult, any>({
            path: `/batching/map/${mapId}/files`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name CreateBatchingRawFile
     * @request POST:/batching/map/{mapId}/batching
     * @secure
     */
    createBatchingRawFile = (mapId: string, data: BatchingRawFileCreate, params: RequestParams = {}) =>
        this.request<BatchingRawFileCreateResult, ApiErrorsResponse>({
            path: `/batching/map/${mapId}/batching`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name SetBatchingRawFileUploaded
     * @request POST:/batching/map/{mapId}/batching/{fileId}/uploaded
     * @secure
     */
    setBatchingRawFileUploaded = (mapId: string, fileId: string, params: RequestParams = {}) =>
        this.request<BatchingRawFileDetails, ApiErrorsResponse>({
            path: `/batching/map/${mapId}/batching/${fileId}/uploaded`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name GetBatchingRawFileDownloadUrl
     * @request GET:/batching/map/{mapId}/batching/{fileId}/download
     * @secure
     */
    getBatchingRawFileDownloadUrl = (mapId: string, fileId: string, params: RequestParams = {}) =>
        this.request<StorageDownloadLinkResult, ApiErrorsResponse>({
            path: `/batching/map/${mapId}/batching/${fileId}/download`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name DeleteBatchingRawFile
     * @request DELETE:/batching/map/{mapId}/batching/{fileId}
     * @secure
     */
    deleteBatchingRawFile = (mapId: string, fileId: string, params: RequestParams = {}) =>
        this.request<void, ApiErrorsResponse>({
            path: `/batching/map/${mapId}/batching/${fileId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
}
